// @ts-nocheck
import React from "react";
import styled from "styled-components";
import FlexDisplayItem from "../FlexDisplayItem";

export type HeadingProps = {
  level?: number | string;
  children?: React.ReactNode;
  textAlignment?: "Left" | "Center" | "Right" | undefined;
  flexDisplayVerticalAlignment: "Top" | "Center" | "Bottom" | undefined;
  flexDisplayHorizontalAlignment: "Left" | "Center" | "Right" | undefined;
  style?: any;
};
const Heading: React.FC<HeadingProps> = (data) => {
  // console.log("data", data);
  const { level = 1, children, style, title, textAlignment } = data;
  let lvl = 1;
  if (level && level.toString().indexOf("h") > -1) {
    lvl = level.substring(level.length - 1, level.length);
  } else {
    lvl = level;
  }
  const StyledHeading = styled.div`
    text-align: ${(props) =>
      props.textAlignment
        ? props.textAlignment.toLowerCase()
        : "left"} !important;
  `;

  return (
    <>
      <FlexDisplayItem
        fv={data.flexDisplayVerticalAlignment}
        fh={data.flexDisplayHorizontalAlignment}
        textAlignment={textAlignment}
      >
        <StyledHeading
          as={`h${lvl}`}
          level={lvl}
          textAlignment={textAlignment}
          style={style}
        >
          {title || children}
        </StyledHeading>
      </FlexDisplayItem>
    </>
  );
};

export default Heading;
